export const BreadCrumbsListing = {
  default_business_setting: {
    breadcrumbs: ["Settings", "Default Business Settings"],
    component: "Default Business Settings",
    url: "default_business_setting",
  },
  programs: {
    breadcrumbs: ["Programs", "All Programs"],
    component: "All Programs",
    url: "programs",
  },
  add_program: {
    breadcrumbs: ["Programs", "Add Program"],
    component: "Add Program",
    url: "programs",
  },
  courses: {
    breadcrumbs: ["Courses", "All Courses"],
    component: "All Courses",
    url: "courses",
  },
  users: {
    breadcrumbs: ["Users", "All Users"],
    component: "All Users",
    url: "users",
  },
  modules: {
    breadcrumbs: ["Modules", "All Modules"],
    component: "All Modules",
    url: "modules",
  },
  units: {
    breadcrumbs: ["Units", "All Units"],
    component: "All Units",
    url: "units",
  },
  add_unit: {
    breadcrumbs: ["Units", "Add Unit"],
    component: "Add Unit",
    url: "units",
  },
};
