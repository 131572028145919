import React from "react";
import PropTypes from "prop-types";

function SelectComponent({
  name,
  label,
  classes,
  optionsList,
  value,
  onChange,
  error,
}) {
  return (
    <div
      className={`selectField ${classes.fieldClass ? classes.fieldClass : ""}`}
    >
      <label className={classes.labelClass ? classes.labelClass : ""}>
        {label}
      </label>
      <select
        value={value}
        name={name}
        onChange={(e) => onChange(name, e.target.value)}
      >
        <option value={""}>Select</option>
        {optionsList.map((singleOption, index) => {
          return (
            <option key={index} value={singleOption.value}>
              {singleOption.displayValue}
            </option>
          );
        })}
      </select>
      {error && <span className="error">{error}</span>}
    </div>
  );
}
SelectComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  optionsList: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};
export default SelectComponent;
