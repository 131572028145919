import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import DropZoneAdd from "assets/images/general/dropzone_add.svg";
import CloseImage from "assets/images/general/close-grey.svg";

function SingleFileDropZoneComponent({
  name,
  value,
  label,
  classes,
  setValue,
  error,
}) {
  const [files, setFiles] = useState([]);
  const [isFilesUpdated, setIsFilesUpdated] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setIsFilesUpdated(true);
    },
  });

  useEffect(() => {
    if (files.length > 0 && isFilesUpdated) {
      setValue(name, files.length > 0 ? files[0] : null);
      setIsFilesUpdated(false);
    }
  }, [files, name, setValue, setIsFilesUpdated, isFilesUpdated]);

  const removeMedia = () => {
    setValue(name, null);
    setFiles([]);
  };

  return (
    <div
      className={`dropzone-component ${
        classes.fieldClass ? classes.fieldClass : ""
      }`}
    >
      <label className={classes.labelClass ? classes.labelClass : ""}>
        {label}
      </label>
      {files.length > 0 || value ? (
        <>
          <div className="preview">
            {files && files.length > 0 ? (
              <img src={URL.createObjectURL(files[0])} alt="single logo" />
            ) : null}
            {value && value.url ? (
              <img src={value.url} alt="multiple logo" />
            ) : null}
            <img
              src={CloseImage}
              onClick={removeMedia}
              className="close-image"
              alt="close"
            />
          </div>
          <div className="mx-auto d-flex gap-3 align-items-center">
            {value && value.url && (
              <>
                <span>{value.name}</span>
                <span>{value.size}</span>
              </>
            )}
            {files && files.length > 0 && (
              <>
                <span>{files[0].name}</span>
                <span>{files[0].size}</span>
              </>
            )}
          </div>
        </>
      ) : (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} multiple={false} />

          <img src={DropZoneAdd} alt="DropZoneAdd" />
          <span className="single">Add Image</span>
        </div>
      )}
    </div>
  );
}

SingleFileDropZoneComponent.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.object,
  error: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  isMultipleAllowed: PropTypes.bool.isRequired,
};
export default SingleFileDropZoneComponent;
