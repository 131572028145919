import React, { useEffect } from "react";
import PropTypes from "prop-types";

function ProfileMenu({ setDisplayProfileMenu }) {
  useEffect(() => {
    window.addEventListener("mouseup", function (event) {
      var pol = document.getElementById("profileDetails");
      if (event.target !== pol && event.target.parentNode !== pol) {
        setDisplayProfileMenu(false);
      }
    });
  }, [setDisplayProfileMenu]);
  return (
    <div id="profileDetails" className="profile_menu">
      <img src="" alt="close"/>
      <span>Super Admin</span>
    </div>
  );
}

ProfileMenu.propTypes = {
  setDisplayProfileMenu: PropTypes.func.isRequired,
};
export default ProfileMenu;
