import React from "react";
import PropTypes from "prop-types";

function TableComponent({ data, header }) {
  return (
    <div className="custom-table">
      <table className="table">
        <thead>
          <tr>
            {header.map((singleHeaderField, headerIndex) => {
              return (
                <td key={headerIndex}>{singleHeaderField.displayValue}</td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((singleRecordDetail, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {header.map((singleHeaderField, columnIndex) => {
                  return (
                    <td key={columnIndex}>
                      {singleRecordDetail[singleHeaderField.key]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
};
export default TableComponent;
