import React, { useEffect } from "react";
import ButtonComponent from "components/ButtonComponent";
import Logo from "assets/images/general/logo.svg";
import { useLocation } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";

function SetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const updatePassword = async () => {
    window.location.href = process.env.REACT_APP_REGISTER_LINK;
  };

  const fetchDetail = async () => {
    const CLIENT_ID = process.env.REACT_APP_POOL_CLIENT_ID;
    const CLIENT_SECRET = process.env.REACT_APP_POOL_CLIENT_SECRET;
    let authorization_data = CLIENT_ID + ":" + CLIENT_SECRET;

    let authorization = base64_encode(authorization_data);
    let map = {
      grant_type: "authorization_code",
      code: queryParams.get("code"),
      redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/register`,
      client_id: CLIENT_ID,
    };

    var formBody = [];
    for (var property in map) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(map[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let response = await fetch(process.env.REACT_APP_AUTHENTICATE_CODE, {
      method: "POST",
      async: false,
      body: formBody,
      headers: {
        authorization: "Basic " + authorization,
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    const ret_data = await response.json();
    return ret_data;
  };
  useEffect(() => {
    if (queryParams.get("code") && queryParams.get("code") !== "") {
      fetchDetail();
    }
  }, [queryParams.get("code")]);
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="header">
        <h2 className="theme-font-inter-bold">Welcome To BlackForce</h2>
      </div>
      <div className="d-flex gap-3 align-items-center">
        <ButtonComponent
          text="Register user"
          onClick={updatePassword}
          variant="blue-1"
        />
        <ButtonComponent
          text="Fetch Detail"
          onClick={fetchDetail}
          variant="blue-1"
        />
      </div>
    </div>
  );
}

export default SetPassword;
