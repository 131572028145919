import React, { useState } from "react";
import SearchImage from "assets/images/top_bar/search_image.svg";
import ButtonComponent from "./ButtonComponent";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { getBreadcrumbType } from "recoil/General";
import ProfileMenu from "./ProfileMenu";
import { authState } from "recoil/Auth";
import { useNavigate } from "react-router-dom";
import { BreadCrumbsListing } from "config/BreadCrumbsListing";

function TopBar() {
  const navigate = useNavigate();
  const [displayProfileMenu, setDisplayProfileMenu] = useState(false);

  const typeOfBreadCrumb = useRecoilValue(getBreadcrumbType);
  const switchUser = () => {};
  const resetState = useResetRecoilState(authState);
  const logout = () => {
    resetState();
  };
  const moveTo = (link) => {
    navigate(link);
  };
  return (
    <div className={`top_bar d-flex flex-column`}>
      <div className="header d-flex align-items-center justify-content-between p-4">
        <div className="breadcrumb mb-0">
          {BreadCrumbsListing[typeOfBreadCrumb]
            ? BreadCrumbsListing[typeOfBreadCrumb].breadcrumbs.map(
                (singleItem, index) => {
                  return (
                    <div key={index}>
                      {index !== 0 && <span className="mx-2">-</span>}
                      <span
                        onClick={() => {
                          if (index === 0) {
                            moveTo(BreadCrumbsListing[typeOfBreadCrumb].url);
                          }
                        }}
                      >
                        {singleItem}
                      </span>
                    </div>
                  );
                }
              )
            : ""}
        </div>
        <div className="others d-flex align-items-center gap-3">
          <span>
            <img src={SearchImage} alt="Search" />
          </span>
          <ButtonComponent
            text="Switch To User"
            onClick={switchUser}
            variant="white-blue-1"
            loading={false}
            disabled={false}
          />
          <span onClick={() => setDisplayProfileMenu(!displayProfileMenu)}>
            Profile
          </span>
          <span onClick={logout}>Logout</span>
          {displayProfileMenu && (
            <ProfileMenu setDisplayProfileMenu={setDisplayProfileMenu} />
          )}
        </div>
      </div>
      <div className="component theme-font-inter-medium p-4">
        {BreadCrumbsListing[typeOfBreadCrumb]
          ? BreadCrumbsListing[typeOfBreadCrumb].component
          : ""}
      </div>
    </div>
  );
}

export default TopBar;
