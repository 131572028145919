export const UserTableHeader = [
  { key: "name", displayValue: "Name", sort: true },
  { key: "username", displayValue: "User Name", sort: true },
  { key: "email", displayValue: "Email", sort: true },
  { key: "role_name", displayValue: "Role", sort: true },
  { key: "department_name", displayValue: "Department", sort: true },
  { key: "profile_name", displayValue: "Profile", sort: true },
];
export const AddUnitTabs = [
  {
    displayValue: "Basic Details",
  },
  {
    displayValue: "Tags & Highlights",
  },
  {
    displayValue: "Notes & Units",
  },
  {
    displayValue: "Preview",
  },
];
