import React, { useState } from "react";
import ButtonComponent from "components/ButtonComponent";
import SelectComponent from "components/SelectComponent";
import TextComponent from "components/TextComponent";
import TextRadioComponent from "components/TextRadioComponent";

function AuthSettings() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    sso_enable: true,
    business_id: "",
    role: "",
    profile: "",
    department: "",
    subscription_email: "",
  });
  const updateSettings = () => {
    setLoading(!loading);
  };
  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-3 page-header">
        <span>Auth Settings</span>
        <div>
          <ButtonComponent
            text="Update Settings"
            onClick={updateSettings}
            variant="blue-1"
            loading={loading}
            disabled={loading}
          />
        </div>
      </div>
      <div className="p-5 gap-5 box-width">
        <div className="d-flex gap-4">
          <TextRadioComponent
            name={"sso_enable"}
            label={"SSO Enable"}
            placeholder={"Enable or Disable SSO"}
            value={values.sso_enable}
            setValue={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
              fieldClass: "pt-3 pb-5",
            }}
          />
        </div>
        <div className="d-flex gap-4">
          <SelectComponent
            name={"profile"}
            label={"Default Profile"}
            value={values.profile}
            onChange={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
              fieldClass: "pt-3 pb-5",
            }}
            optionsList={[
              { value: "male", displayValue: "Male" },
              { value: "female", displayValue: "Female" },
            ]}
          />
        </div>
        <div className="d-flex gap-4">
          <TextComponent
            name={"business_id"}
            label={"Default Business"}
            placeholder={"Default Business"}
            value={values.business_id}
            setValue={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
              fieldClass: "pt-3 pb-5",
            }}
          />
        </div>
        <div className="d-flex gap-4">
          <SelectComponent
            name={"department"}
            label={"Default Department"}
            value={values.department}
            onChange={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
              fieldClass: "pt-3 pb-5",
            }}
            optionsList={[
              { value: "male", displayValue: "Male" },
              { value: "female", displayValue: "Female" },
            ]}
          />
        </div>
        <div className="d-flex gap-4">
          <SelectComponent
            name={"role"}
            label={"Default Role"}
            value={values.role}
            onChange={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
              fieldClass: "pt-3 pb-5",
            }}
            optionsList={[
              { value: "male", displayValue: "Male" },
              { value: "female", displayValue: "Female" },
            ]}
          />
        </div>
        <div className="d-flex gap-4">
          <TextComponent
            name={"subscription_email"}
            label={"Subscription management email"}
            placeholder={"Subscription management email"}
            value={values.subscription_email}
            setValue={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
              fieldClass: "pt-3 pb-5",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AuthSettings;
