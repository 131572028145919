import React from "react";
import PropTypes from "prop-types";

function ButtonComponent({ text, onClick, disabled, variant, loading }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={variant ? "variant-" + variant : ""}
    >
      {text}
      {loading ? <div className="spinner-border"></div> : ""}
    </button>
  );
}

ButtonComponent.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ButtonComponent;
