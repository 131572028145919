import React from "react";
import PropTypes from "prop-types";

function Preview({ values }) {
  return <div>Preview</div>;
}
Preview.propTypes = {
  values: PropTypes.object.isRequired,
};
export default Preview;
