import React from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import TextAreaComponent from "components/TextAreaComponent";
import TextComponent from "components/TextComponent";
import { CompetencyLevels } from "config/config";
import SingleFileDropZoneComponent from "components/SingleFileDropZoneComponent";

function BasicDetails({ values, errors, updateFormValues }) {
  return (
    <>
      <div className="form mb-4 d-flex flex-column gap-3">
        <div className="d-flex gap-3 w-100 box-width">
          <TextComponent
            name={"title"}
            label={"Enter Title"}
            placeholder={"Title"}
            value={values.title}
            setValue={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
            }}
            error={errors["title"] ?? ""}
          />
          <TextComponent
            name={"reward_credits"}
            label={"Reward Credits"}
            placeholder={"Reward Credits"}
            value={values.reward_credits}
            setValue={updateFormValues}
            classes={{
              labelClass: "auth-form-field-label-type-2",
            }}
            error={errors["reward_credits"] ?? ""}
          />
          <SelectComponent
            name={"competency_level"}
            label={"Competency Level"}
            value={values.competency_level}
            onChange={updateFormValues}
            classes={{ labelClass: "auth-form-field-label-type-2" }}
            optionsList={CompetencyLevels}
            error={errors["competency_level"] ?? ""}
          />
        </div>
      </div>
      <div className="mb-4">
        <SingleFileDropZoneComponent
          name={"banner"}
          label={"Banner Image"}
          value={values.banner}
          setValue={updateFormValues}
          classes={{
            labelClass: "auth-form-field-label-type-2",
            fieldClass: "half",
          }}
          isMultipleAllowed={false}
          type={"image"}
        />
      </div>
      <div>
        <TextAreaComponent
          name={"detail"}
          label={"Description"}
          placeholder={"Description"}
          value={values.details}
          setValue={updateFormValues}
          classes={{
            labelClass: "auth-form-field-label-type-2",
          }}
        />
      </div>
    </>
  );
}
BasicDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default BasicDetails;
