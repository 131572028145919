import { CallAPI } from "actions/General";
import TableComponent from "components/TableComponent";
import React, { useEffect, useState } from "react";
import { UserTableHeader } from "./utils";
import { useRecoilValue } from "recoil";
import { getCognitoToken } from "recoil/Auth";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";

function Users() {
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");
  const token = useRecoilValue(getCognitoToken);
  const getUserList = async () => {
    let result = await CallAPI(
      "FETCH_USERS_LIST",
      { token },
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) setUsers(result.data);
  };
  useEffect(() => {
    getUserList();
  }, []);
  return (
    <>
      <div>
        {loader && <LoaderComponent classes={"mx-4"} />}
        {apiError && <ErrorComponent text={apiError} variant="error" />}
        <span className="pb-4 px-4 theme-font-inter-medium theme-font-size-16">
          Users
        </span>
        <TableComponent data={users} header={UserTableHeader} />
      </div>
      <div>Testing data</div>
    </>
  );
}

export default Users;
