import React from "react";
import PropTypes from "prop-types";

function PasswordComponent({
  name,
  value,
  label,
  classes,
  placeholder,
  setValue,error
}) {
  return (
    <div className="textField">
      <label className={classes.labelClass ? classes.labelClass : ""}>{label}</label>
      <input
        type="password"
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => setValue(name, e.target.value)}
      />
      {error && <span className="error">{error}</span>}
      </div>
  );
}
PasswordComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};
export default PasswordComponent;
