import React from "react";
import PropTypes from "prop-types";

function TabsComponent({ tabs, selectedTab, setSelectedTab, children }) {
  return (
    <>
      <div className="tabs">
        {tabs.map((singleTab, index) => (
          <span
            key={index}
            className={index + 1 === selectedTab ? "active" : ""}
            onClick={() => setSelectedTab(index + 1)}
          >
            {singleTab.displayValue}
          </span>
        ))}
      </div>
      {children}
    </>
  );
}
TabsComponent.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  children: PropTypes.any,
};
export default TabsComponent;
