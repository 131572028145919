import React from "react";
import PropTypes from "prop-types";

function ErrorComponent({ text, variant }) {
  return (
    <div className={`alert alert-${variant} mb-0`} role={"alert"}>
      {text}
    </div>
  );
}
ErrorComponent.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
export default ErrorComponent;
