import React, { useState } from "react";
import Logo from "assets/images/general/logo.svg";
import TextComponent from "components/TextComponent";
import PasswordComponent from "components/PasswordComponent";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";
import ErrorComponent from "components/ErrorComponent";
import { SuperAdminLoginSchema } from "./FieldValidationRoles";
import { useRecoilState } from "recoil";
import { authState } from "recoil/Auth";

function SuperAdminLogin() {
  const errorsInitialState = { email: "", password: "" };

  const navigate = useNavigate();

  const [authRecoilState, setAuthRecoilState] = useRecoilState(authState);

  const [values, setValues] = useState({
    email: "super-admin@gmail.com",
    password: "123456",
  });
  const [errors, setErrors] = useState(errorsInitialState);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const loginAction = async () => {
    try {
      setErrors(errorsInitialState);
      await SuperAdminLoginSchema.validate(values, { abortEarly: false });
      setLoader(true);
      let result = await CallAPI(
        "SUPER_ADMIN_LOGIN",
        values,
        null,
        setLoader,
        setApiError,
        navigate
      );
      if (result.status) {
        setAuthRecoilState({ ...authRecoilState, ...result.data });
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="header">
        <h2 className="theme-font-inter-bold">Welcome To BlackForce</h2>
        <span className="theme-font-inter-regular">
          Enter your credentials to access your account
        </span>
      </div>
      <div className="form mb-4 box-width">
        {apiError && <ErrorComponent text={apiError} variant={"danger"} />}
        <TextComponent
          name={"email"}
          label={"Email"}
          placeholder={"abc@gmail.com"}
          value={values.email}
          setValue={updateFormValues}
          classes={{ labelClass: "auth-form-field-label-type-1" }}
          error={errors.email}
        />
        <PasswordComponent
          name={"password"}
          label={"Password"}
          placeholder={"Password"}
          value={values.password}
          setValue={updateFormValues}
          classes={{ labelClass: "auth-form-field-label-type-1" }}
          error={errors.password}
        />
        <ButtonComponent
          text="Login"
          onClick={loginAction}
          disabled={loader}
          loading={loader}
          variant={"blue-1"}
        />
      </div>
    </div>
  );
}

export default SuperAdminLogin;
