import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import DropZoneAdd from "assets/images/general/dropzone_add.svg";
import DropZoneAddMultiple from "assets/images/general/dropzone_add_multiple.svg";
import ButtonComponent from "./ButtonComponent";
import DocumentImage from "assets/images/general/document-solid.svg";

function MultipleFileDropZoneComponent({
  name,
  value,
  label,
  classes,
  setValue,
  isMultipleAllowed,
  error,
  type,
}) {
  console.log("value", value);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: isMultipleAllowed,
    accept: isMultipleAllowed
      ? {}
      : {
          "image/*": [],
        },

    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    console.log("files", files);
    if (files.length > 0)
      setValue(
        name,
        isMultipleAllowed ? files : files.length > 0 ? [files[0]] : []
      );
  }, [files, isMultipleAllowed, name, setValue]);

  return (
    <div
      className={`dropzone-component ${
        classes.fieldClass ? classes.fieldClass : ""
      }`}
    >
      <label className={classes.labelClass ? classes.labelClass : ""}>
        {label}
      </label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} multiple={false} />
        {isMultipleAllowed ? (
          <>
            <img src={DropZoneAddMultiple} alt="DropZoneAdd" />
            <div>
              <ButtonComponent
                text="Choose File to upload"
                variant="blue-1 px-5"
                loading={false}
                disabled={false}
                onClick={() => {}}
              />
            </div>
            <span className="multiple">Or drag & drop them here</span>
          </>
        ) : (
          <>
            <img src={DropZoneAdd} alt="DropZoneAdd" />
            <span className="single">Add Image</span>
          </>
        )}
      </div>

      <div className="d-flex gap-3 flex-wrap view">
        {type === "image" ? (
          <>
            {files
              ? files.map((singleFile, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(singleFile)}
                    alt="single logo"
                  />
                ))
              : null}
            {value
              ? value.map(
                  (singleFile, index) =>
                    singleFile &&
                    singleFile.url && (
                      <img key={index} src={singleFile} alt="multiple logo" />
                    )
                )
              : null}
          </>
        ) : (
          <>
            {files
              ? files.map((singleFile, index) => (
                  <div className="detail" key={index}>
                    <img src={DocumentImage} alt="detail icon" />
                    <div>
                      <span className="title">{singleFile.name}</span>
                      <span className="size">{singleFile.size}</span>
                    </div>
                  </div>
                ))
              : null}
            {value
              ? value.map(
                  (singleFile, index) =>
                    singleFile &&
                    singleFile.url && (
                      <div className="detail" key={index}>
                        <img src={DocumentImage} alt="detail icon" />
                        <div>
                          <span className="title">{singleFile.name}</span>
                          <span className="size">{singleFile.size}</span>
                        </div>
                      </div>
                    )
                )
              : null}
          </>
        )}
      </div>
    </div>
  );
}

MultipleFileDropZoneComponent.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  error: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  isMultipleAllowed: PropTypes.bool.isRequired,
};
export default MultipleFileDropZoneComponent;
