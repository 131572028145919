import { Routes, Route, BrowserRouter } from "react-router-dom";
import NonAuthenticatedLayout from "layouts/NonAuthenticatedLayout";
import Login from "views/auth/Login";
// import Register from "views/auth/Register";
import AuthenticatedLayout from "layouts/AuthenticatedLayout";
import AuthSettings from "views/settings/AuthSettings";
import Programs from "views/programs/Programs";
import Courses from "views/courses/Courses";
import SuperAdminLogin from "views/auth/SuperAdminLogin";
import Dashboard from "views/dashboard/Dashboard";
import ChooseBusiness from "views/auth/ChooseBusiness";
import Users from "views/users/Users";
import AddProgram from "views/programs/AddProgram";
import "./axios/Axios";
import Units from "views/units/Units";
import AddUnit from "views/units/AddUnit";
import LoginWithKeys from "views/auth/LoginWithKeys";
import SetPassword from "views/auth/SetPassword";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<NonAuthenticatedLayout />}>
            <Route path="/" element={<Login />} />
            <Route
              path="/login_with_keys/:public_key/:private_key/:module_id"
              element={<LoginWithKeys />}
            />
            <Route path="/password" element={<SetPassword />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/register" element={<SetPassword />} />
            <Route path="/super_admin" element={<SuperAdminLogin />} />
            <Route path="/choose_business" element={<ChooseBusiness />} />
          </Route>
          <Route element={<AuthenticatedLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/default_business_setting"
              element={<AuthSettings />}
            />
            <Route path="/programs" element={<Programs />} />
            <Route path="/add_program" element={<AddProgram />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/users" element={<Users />} />

            <Route path="/units" element={<Units />} />
            <Route path="/add_unit" element={<AddUnit />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
