import React, { useState } from "react";
import TabsComponent from "components/TabsComponent";
import { AddUnitTabs } from "./utils";
import BasicDetails from "./BasicDetails";
import TagsLearnHighlights from "./TagsLearnHighlights";
import NotesUnits from "./NotesUnits";
import Preview from "./Preview";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import {
  BasicDetailsSchema,
  NotesUnitsSchema,
  TagsLearnHighlightsSchema,
} from "./FieldValidationRoles";
import { CallAPI } from "actions/General";

function AddUnit() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [values, setValues] = useState({
    title: "",
    reward_credits: "",
    competency_level: "",
    details: "",
    banner: null,
    tags: [],
    learning_points: [],
    urls: [],
    documents: [],
  });
  const [errors, setErrors] = useState({});

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const previousClick = () => {
    if (selectedTab === 1) {
      navigate("/units");
    } else {
      setSelectedTab(selectedTab - 1);
    }
  };
  const getAndUploadMedia = async () => {
    const response = await CallAPI(
      "GET_PRESIGNED_URL",
      { name: "11" },
      null,
      null,
      null,
      null
    );
    console.log("response", response, values.banner);
    // await CallAPI(
    //   "UPLOAD_MEDIA_TO_STORAGE",
    //   { body: values.banner },
    //   response.data.url,
    //   null,
    //   null,
    //   null
    // );
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "image/jpeg");
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: values.banner,
      redirect: "follow",
    };
    await fetch(response.data.url, requestOptions)
      .then(() => {
        console.log("uploaded");
      })
      .catch((error) => console.log("error", error));
    await CallAPI("GET_PREVIEW_PRESIGNED_URL", null, null, null, null, null);
  };

  const nextClick = async () => {
    if (selectedTab === AddUnitTabs.length) {
      submitForm();
    } else {
      try {
        setErrors({});
        getAndUploadMedia();

        const errorValidatorSchema =
          selectedTab === 1
            ? BasicDetailsSchema
            : selectedTab === 2
            ? TagsLearnHighlightsSchema
            : NotesUnitsSchema;
        await errorValidatorSchema.validate(values, { abortEarly: false });
        setSelectedTab(selectedTab + 1);
      } catch (error) {
        console.log("error", error);
        const schemaErrors = {};
        error.inner?.forEach((err) => {
          schemaErrors[err.path] = err.message;
        });
        setErrors(schemaErrors);
      }
    }
  };
  const submitForm = async () => {
    console.log(values);
    const unitDetail = {
      title: values.title,
      credits: parseInt(values.reward_credits),
      competency_level: values.competency_level,
      banner_image_id: values.banner_image_id,
      description: values.details,
      tags: [],
      learning_points: values.learning_points,
      documents: [],
      urls: values.urls,
    };
    console.log("unitDetail", unitDetail);
    let result = await CallAPI("ADD_UNIT", unitDetail, null, null, null, null);
    console.log("result", result);
  };
  return (
    <div className="p-4">
      <TabsComponent
        tabs={AddUnitTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        <div className="pt-5 pb-3 px-3">
          {selectedTab === 1 ? (
            <BasicDetails
              errors={errors}
              values={values}
              updateFormValues={updateFormValues}
            />
          ) : selectedTab === 2 ? (
            <TagsLearnHighlights
              errors={errors}
              values={values}
              updateFormValues={updateFormValues}
            />
          ) : selectedTab === 3 ? (
            <NotesUnits
              errors={errors}
              values={values}
              updateFormValues={updateFormValues}
            />
          ) : selectedTab === 4 ? (
            <Preview values={values} />
          ) : (
            <></>
          )}
        </div>

        <div className="w-auto d-flex justify-content-end gap-3 px-3">
          <ButtonComponent
            text={selectedTab === 1 ? "Cancel" : "Previous"}
            onClick={previousClick}
            variant="grey-1 w-auto"
            loading={false}
            disabled={false}
          />
          <ButtonComponent
            text={selectedTab === AddUnitTabs.length ? "Submit" : "Next"}
            onClick={nextClick}
            variant="blue-1 w-auto"
            loading={false}
            disabled={false}
          />
        </div>
      </TabsComponent>
    </div>
  );
}

export default AddUnit;
