import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Logo from "assets/images/general/logo.svg";
import { CallAPI } from "actions/General";
import { useRecoilState } from "recoil";
import { authState } from "recoil/Auth";

function LoginWithKeys() {
  const params = useParams();
  console.log("params", params);
  const [authSettingDetail, setAuthSettingsDetail] = useRecoilState(authState);
  const loginUserViaKeys = async (params) => {
    let result = await CallAPI(
      "LOGIN_USER_VIA_KEYS",
      params,
      null,
      null,
      null,
      null
    );
    console.log("result LOGIN_USER_VIA_KEYS", result);
    if (result.status) {
      let authObject = {
        authToken: result.data.access_token,
        email: result.data.email,
        id: result.data.user_id,
        role: "user",
        businessesList: result.data.businessesList,
        details: {
          business_id: result.data.business_id,
          profile: result.data.profile,
          profile_name: result.data.profile_name,
          department: result.data.department,
          department_name: result.data.department_name,
          department_id:result.data.department_id,
          role: result.data.role,
          role_name: result.data.role_name,
          role_level: result.data.role_level,
          external_user_id:result.data.external_user_id,
          user_status:result.data.user_status,
          is_user:result.data.is_user,
          user_id: result.data.user_id,
          user_name: result.data.user_name,
          job_title: result.data.job_title,
          about: result.data.about,
          avatar: result.data.avatar,
          total_credits: result.data.total_credits,
          wallet_pin: result.data.wallet_pin,
        },
      };
     
      setAuthSettingsDetail(authObject);
    }
  };
  useEffect(() => {
    loginUserViaKeys(params);
  }, [params]);
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="header">
        <h2 className="theme-font-inter-bold">Welcome To BlackForce</h2>
      </div>
      <div className="d-flex gap-3 align-items-center">
        <h2 className="theme-font-inter-bold mb-0">Please Wait</h2>
        <div className="spinner-border"></div>
      </div>
    </div>
  );
}

export default LoginWithKeys;
