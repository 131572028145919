import SideBar from "components/SideBar";
import TopBar from "components/TopBar";
import React, { Fragment, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { isUserHasRole, isUserLoggedIn } from "recoil/Auth";
import { generalState, isSideBarOpened } from "recoil/General";

const AuthenticatedLayout = () => {
  const location = useLocation();
  const isLogin = useRecoilValue(isUserLoggedIn);
  const userHasRole = useRecoilValue(isUserHasRole);
  const isSidebarOpened = useRecoilValue(isSideBarOpened);
  const [commonSetting, setCommonSettings] = useRecoilState(generalState);

  useEffect(() => {
    let breadcrumb_type = location.pathname.substring(
      1,
      location.pathname.length
    );
    if (breadcrumb_type !== commonSetting.breadcrumb_type)
      setCommonSettings({
        ...commonSetting,
        breadcrumb_type: breadcrumb_type,
      });
  }, [location.pathname, commonSetting, setCommonSettings]);

  return (
    <Fragment>
      <div className="auth-layout">
        {isLogin && userHasRole ? (
          <>
            <SideBar />
            <div className={`content ${isSidebarOpened ? "small" : "large"}`}>
              <TopBar />
              <div className="page p-4">
                <Outlet />
              </div>
            </div>
          </>
        ) : isLogin ? (
          <Navigate to="/choose_business" replace state={{ from: location }} />
        ) : (
          <Navigate to="/login" replace state={{ from: location }} />
        )}
      </div>
    </Fragment>
  );
};

export default AuthenticatedLayout;
