import React from "react";
import PropTypes from "prop-types";

function TextRadioComponent({
  name,
  value,
  label,
  classes,
  placeholder,
  setValue,
}) {
  return (
    <div
      className={`textField ${classes.fieldClass ? classes.fieldClass : ""}`}
    >
      <label className={classes.labelClass ? classes.labelClass : ""}>
        {label}
      </label>
      <input
        disabled
        value={placeholder}
        name={name}
        onChange={(e) => setValue(name, e.target.value)}
      />
      <label className="switch">
        <input type="checkbox" />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
TextRadioComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default TextRadioComponent;
