import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    let apiPath = process.env.REACT_APP_API_PATH;
    let url = config.url;

    let isRetry = config._retry;
    let tokenType = config.headers.token_type;
    if (!isRetry) {
      config.url = apiPath + url;
    } else {
      tokenType = config.headers["token-type"];
    }
    // console.log("tokenType", tokenType);

    const tokenState = JSON.parse(window.localStorage.getItem("LMS-local"));
    // console.log("token", tokenState);
    // console.log("token", tokenState.authState.authToken);
    // let authToken = process.env.REACT_APP_STATIC_TOKEN;
    let authToken =
      tokenType === "default"
        ? process.env.REACT_APP_STATIC_TOKEN
        : tokenType === "cognito"
        ? tokenState.authState.cognitoToken
        : tokenType === "external"
        ? ""
        : tokenState.authState.authToken;
    // : "";
    // console.log("authToken", authToken);
    //     ? auth.loginToken
    //     : tokenType === 'refresh'
    //     ? auth.refreshToken
    //     : tokenType === 'loginRefresh'
    //     ? auth.loginRefreshToken
    //     : tokenType !== 'external'
    //     ? auth.authToken
    //     : '';
    let headers = {
      "content-type": "application/json",
      Authorization: authToken ? "Bearer " + authToken : "",
      "token-type": tokenType,
    };
    config.headers = headers;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // RETURN SUCCESS RESPONSE
    if (response.status === 200 || response.status === 201) {
      return { data: response.data.data, error: "" };
    } else {
      // RETURN ERROR RESPONSE
      return { data: {}, error: response.error.msg };
    }
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      if (
        originalRequest.url.indexOf("refresh-token") < 1 &&
        originalRequest._retry !== true
      ) {
        // if (originalRequest.headers["token-type"] === "loginToken")
        //   await CallAPI("LOGIN_REFRESH_TOKEN", {}, null, null, null, null);
        // else await CallAPI("REFRESH_TOKEN", {}, null, null, null, null);
        originalRequest._retry = true;
        return new Promise((resolve) => {
          resolve(axios(originalRequest));
        });
      } else {
        // store.dispatch(
        //   AuthActions.updateAuthState({
        //     loginToken: null,
        //     loginRefreshToken: null,
        //     authToken: null,
        //     refreshToken: null,
        //     profile: null,
        //   })
        // );
        // window.location.href = "/super_admin";
        return {
          data: {},
          error: "401" ?? "no message",
        };
      }
    } else if (error.response && error.response.status === 403) {
      return {
        data: {},
        error: "you are not authorized to perform this operation",
      };
    } else if (error.response) {
      return {
        data: {},
        error: error.response.message ?? "no message",
      };
    } else {
      return {
        data: {},
        error: "nothing found",
      };
    }
  }
);
