import React, { useEffect, useState } from "react";
import Logo from "assets/images/general/logo.svg";
import TextComponent from "components/TextComponent";
import PasswordComponent from "components/PasswordComponent";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { authState } from "recoil/Auth";
import { useRecoilState } from "recoil";
import { CallAPI } from "actions/General";
import { UserLoginSchema } from "./FieldValidationRoles";
import ErrorComponent from "components/ErrorComponent";

function Login() {
  const errorsInitialState = { userName: "", password: "" };

  const [authRecoilState, setAuthRecoilState] = useRecoilState(authState);
  const [authSettingDetail, setAuthSettingsDetail] = useRecoilState(authState);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    userName: "dilpazirwhizpool",
    password: "Maz!23456",
  });
  const [errors, setErrors] = useState(errorsInitialState);
  const [loader, setLoader] = useState(false);
  const [loader1, setloader1] = useState(false)
  const [apiError, setApiError] = useState("");

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const loginAction = async () => {
    try {
      setErrors(errorsInitialState);
      await UserLoginSchema.validate(values, { abortEarly: false });
      setLoader(true);
      let result = await CallAPI(
        "USER_LOGIN",
        values,
        null,
        setLoader,
        setApiError,
        null
      );
      console.log(result);
      if (result.status) {
        setAuthRecoilState({
          ...authRecoilState,
          cognitoToken: result.data.idToken,
          businessesList: result.data.businessesList,
        });
        navigate("/choose_business", {
          relative: "path",
          state: { token: result.data.accessToken },
        });
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };
  const SingleLogin = async (data) => { 
    
    let result = await CallAPI(
      "LOGIN_VIA_SINGLE_LOGIN",
      data,
      null,
      setloader1,
      setApiError,
      null
    );
    console.log("result LOGIN_VIA_SINGLE_LOGIN", result);
    if (result.status) {
      let authObject = {
        authToken: result.data.access_token,
        email: result.data.email,
        id: result.data.user_id,
        role: "user",
        businessesList: result.data.businessesList,
        details: {
          business_id: result.data.business_id,
          profile: result.data.profile,
          profile_name: result.data.profile_name,
          department: result.data.department,
          department_name: result.data.department_name,
          department_id:result.data.department_id,
          role: result.data.role,
          role_name: result.data.role_name,
          role_level: result.data.role_level,
          external_user_id:result.data.external_user_id,
          user_status:result.data.user_status,
          is_user:result.data.is_user,
          user_id: result.data.user_id,
          user_name: result.data.user_name,
          job_title: result.data.job_title,
          about: result.data.about,
          avatar: result.data.avatar,
          total_credits: result.data.total_credits,
          wallet_pin: result.data.wallet_pin,
        },
       
        
      };
      console.log(authObject);
      setAuthSettingsDetail(authObject);
    }
  };
  const loginSingleLoginAction = () => {
    window.open(process.env.REACT_APP_LOGIN_URL, "", "width=700,height=700");
  };

  useEffect(() => {
    window.addEventListener("message", handleEvent, false);
    return () => {
      window.removeEventListener("message", handleEvent, false);
    };
  }, []);

  const handleEvent = async (event) => {
    let url = process.env.REACT_APP_LOGIN_URL;
    url = url.split("/");

    var protocol = url[0];
    var host = url[2];
    var origin = protocol + "//" + host;
    if (event.origin === origin) {
      console.log("event", event.data);
      SingleLogin(event.data)
    }
    return;
  };
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="header">
        <h2 className="theme-font-inter-bold">Welcome To BlackForce</h2>
        <span className="theme-font-inter-regular">
          Enter your credentials to access your account
        </span>
      </div>
      <div className="form mb-4 box-width">
        {apiError && <ErrorComponent text={apiError} variant={"danger"} />}
        <TextComponent
          name={"userName"}
          label={"UserName"}
          placeholder={"abc"}
          value={values.userName}
          setValue={updateFormValues}
          classes={{ labelClass: "auth-form-field-label-type-1" }}
          error={errors.userName}
        />
        <PasswordComponent
          name={"password"}
          label={"Password"}
          placeholder={"Password"}
          value={values.password}
          setValue={updateFormValues}
          classes={{ labelClass: "auth-form-field-label-type-1" }}
          error={errors.password}
        />
        <ButtonComponent
          text="Login"
          onClick={loginAction}
          disabled={loader}
          loading={loader}
          variant={"blue-1"}
        />
        <ButtonComponent
          text="Login via Single Login"
          onClick={loginSingleLoginAction}
          disabled={false}
          loading={false}
          variant={"blue-2"}
        />
      </div>
      <span className="theme-font-inter-regular register">
        Don't have an account?{" "}
        <span
          className="theme-font-inter-medium"
          onClick={() => navigate("/register")}
        >
          Register
        </span>
      </span>
    </div>
  );
}

export default Login;
