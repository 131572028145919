import { recoilPersist } from "recoil-persist";

export const { persistAtom } = recoilPersist({
  key: "LMS-" + process.env.REACT_APP_ENV, // this key is using to store data in local storage
  storage: localStorage, // configure which storage will be used to store the data
  converter: JSON,
});

export const CompetencyLevels = [
  { value: "beginner", displayValue: "Beginner" },
  { value: "intermediate", displayValue: "Intermediate" },
  { value: "hard", displayValue: "Hard" },
];
