import React from "react";
import LogoWhite from "assets/images/general/logo-white.svg";
import MenuToggle from "assets/images/general/menu-toggle.svg";
import Dashboard from "assets/images/sidebar/dashboard.svg";
import Programs from "assets/images/sidebar/programs.svg";
import Courses from "assets/images/sidebar/courses.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { generalState, isSideBarOpened } from "recoil/General";

const menuItems = [
  {
    icon: Dashboard,
    title: "Settings",
    url: "default_business_setting",
    type: ["default_business_setting"],
  },
  {
    icon: Programs,
    title: "Programs",
    url: "programs",
    type: ["programs"],
  },
  {
    icon: Courses,
    title: "Courses",
    url: "courses",
    type: ["courses"],
  },
  {
    icon: Courses,
    title: "Users",
    url: "users",
    type: ["users"],
  },
  {
    icon: Courses,
    title: "Units",
    url: "units",
    type: ["units", "add_unit"],
  },
];

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [commonSetting, setCommonSettings] = useRecoilState(generalState);
  const isSidebarOpened = useRecoilValue(isSideBarOpened);
  const navigateToLink = (path) => {
    navigate(path);
  };
  return (
    <div
      className={`sidebar d-flex flex-column  ${
        isSidebarOpened ? "open" : "close align-items-center"
      }`}
    >
      <img
        className="menu_toggler"
        src={MenuToggle}
        alt={"Toggle Menu"}
        onClick={() =>
          setCommonSettings({
            ...commonSetting,
            sidebar_opened: !commonSetting.sidebar_opened,
          })
        }
      />
      <div className="logo">
        <img src={LogoWhite} alt={"white logo"} />
        {isSidebarOpened && <span>BlackForce LMS</span>}
      </div>
      <div className="menu d-flex gap-3 flex-column">
        {menuItems.map((singleMenuItem, index) => {
          return (
            <div
              key={"item_" + index}
              className={`menu_item ${
                singleMenuItem.type.indexOf(
                  location.pathname.replace(location.pathname[0], "")
                ) > -1
                  ? "active"
                  : ""
              } d-flex gap-3`}
              onClick={() => navigateToLink(singleMenuItem.url)}
            >
              <img src={singleMenuItem.icon} alt={singleMenuItem.title} />
              {isSidebarOpened && <span>{singleMenuItem.title}</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
