import React, { useState } from "react";
import PropTypes from "prop-types";
import TextComponent from "./TextComponent";
import ButtonComponent from "./ButtonComponent";
import CloseIcon from "assets/images/general/close.svg";
import MultipleFileDropZoneComponent from "./MultipleFileDropZoneComponent";

function ItemListingComponent({ type, items, updateItems, error }) {
  const [value, setValue] = useState("");
  const updateFormValues = (name, itemValue) => {
    setValue(itemValue);
  };
  const addItemToList = () => {
    updateItems(type, [value, ...items]);
    setValue("");
  };
  const removeItemFromList = (index) => {
    items.splice(index, 1);
    updateItems(type, items);
  };
  return (
    <section className="item-detail-section">
      <header>
        {type === "learning_points"
          ? "What will you learn"
          : type === "tags"
          ? "Add Tags"
          : type === "urls"
          ? "Add Urls"
          : type === "documents"
          ? "Add Documents"
          : ""}
      </header>
      <div className="detail">
        <span className="title">
          {type === "learning_points"
            ? "Learning points"
            : type === "tags"
            ? "Tags"
            : type === "urls"
            ? "urls"
            : type === "documents"
            ? "Add Documents/Notes"
            : ""}
        </span>

        <div className="form mb-4 d-flex flex-column gap-1">
          <div className="d-flex gap-3 w-100 box-width">
            {type === "documents" ? (
              <MultipleFileDropZoneComponent
                name={"banner"}
                label={""}
                value={items}
                setValue={updateItems}
                classes={{}}
                isMultipleAllowed={true}
                type={"document"}
              />
            ) : (
              <>
                <TextComponent
                  name={"title"}
                  label={""}
                  placeholder={
                    type === "learning_points"
                      ? "Add Learning point"
                      : type === "tags"
                      ? "Add Tag"
                      : type === "urls"
                      ? "Add url"
                      : ""
                  }
                  value={value}
                  setValue={updateFormValues}
                  classes={{
                    fieldClass: "half",
                  }}
                />
                <div>
                  <ButtonComponent
                    text="Add"
                    onClick={addItemToList}
                    variant="blue-1 p-3"
                    loading={false}
                    disabled={value.length === 0}
                  />
                </div>
              </>
            )}
          </div>
          {error && <span className="error">{error}</span>}{" "}
        </div>

        <div className={`listing ${type}`}>
          {items.map((singleItem, index) => {
            return (
              <span className={type} key={index}>
                {type === "documents" ? singleItem.name : singleItem}
                <img
                  src={CloseIcon}
                  alt="close icon"
                  className="close"
                  onClick={() => removeItemFromList(index)}
                />
              </span>
            );
          })}
        </div>
      </div>
    </section>
  );
}
ItemListingComponent.propTypes = {
  error: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
};
export default ItemListingComponent;
