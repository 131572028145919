import React, { useEffect, useState } from "react";
import Logo from "assets/images/general/logo.svg";
import ErrorComponent from "components/ErrorComponent";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  authState,
  getUserBusinessesList,
  isUserHasCognitoToken,
} from "recoil/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";

function ChooseBusiness() {
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLogIn = useRecoilValue(isUserHasCognitoToken);
  const businessesList = useRecoilValue(getUserBusinessesList);
  const [cognitoToken, setCognitoToken] = useState("");
  const [authSettingDetail, setAuthSettingsDetail] = useRecoilState(authState);

  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    if (!isUserLogIn) {
      navigate("/login");
    }
  }, [isUserLogIn, navigate]);

  const selectBusiness = async (id) => {
    setSelectedBusiness(id);
    let result = await CallAPI(
      "SELECT_BUSINESS",
      {
        access_token: cognitoToken,
        business_id: id,
      },
      null,
      setLoader,
      setApiError,
      null
    );
    console.log("result", result);
    if (result.status) {
      let authObject = {
        authToken: result.data.access_token,
        email: result.data.email,
        id: result.data.user_id,
        role: "user",
        businessesList: authSettingDetail.businessesList,
        details: {
          business_id: result.data.business_id,
          profile: result.data.profile,
          profile_name: result.data.profile_name,
          department: result.data.department,
          department_name: result.data.department_name,
          department_id:result.data.department_id,
          role: result.data.role,
          role_name: result.data.role_name,
          role_level: result.data.role_level,
          external_user_id:result.data.external_user_id,
          user_status:result.data.user_status,
          is_user:result.data.is_user,
          user_id: result.data.user_id,
          user_name: result.data.user_name,
          job_title: result.data.job_title,
          about: result.data.about,
          avatar: result.data.avatar,
          total_credits: result.data.total_credits,
          wallet_pin: result.data.wallet_pin,
        },
      };
     
      setAuthSettingsDetail(authObject);
    }
  };
  useEffect(() => {
    if (location.state && location.state.token) {
      setCognitoToken(location.state.token);
    }
  }, [location]);
  return (
    <div className="form-component">
      <img src={Logo} alt="form_image" />
      <div className="header">
        <h2 className="theme-font-inter-bold">Welcome To BlackForce</h2>
        <span className="theme-font-inter-regular">
          Select the company as you prefer
        </span>
      </div>
      <div className="form mb-4 box-width">
        {apiError && <ErrorComponent text={apiError} variant={"danger"} />}
        {businessesList.map((singleBusiness) => {
          return (
            <div
              key={singleBusiness.id}
              className="business-item"
              onClick={() => selectBusiness(singleBusiness.id)}
            >
              <span className="theme-font-inter-medium">
                {singleBusiness.name}
                {selectedBusiness === singleBusiness.id && loader ? (
                  <div className="spinner-border"></div>
                ) : null}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChooseBusiness;
