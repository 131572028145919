import { CallAPI } from "actions/General";
import TableComponent from "components/TableComponent";
import React, { useEffect, useState } from "react";
import ErrorComponent from "components/ErrorComponent";
import LoaderComponent from "components/LoaderComponent";
import { UserTableHeader } from "views/users/utils";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";

function Units() {
  const navigate = useNavigate();
  const [units, setUnits] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const getUnitsList = async () => {
    // setLoaderType("get_listing");
    // let result = await CallAPI(
    //   "FETCH_USERS_LIST",
    //   { token, page, limit },
    //   null,
    //   setLoader,
    //   setApiError,
    //   null
    // );
    // if (result.status) setUsers(result.data);
  };
  useEffect(() => {
    getUnitsList();
  }, [page]);
  const addUnit = () => {
    navigate("/add_unit");
  };
  return (
    <>
      <div>
        {loader && loaderType === "get_listing" && (
          <LoaderComponent classes={"mx-4"} />
        )}
        {apiError && <ErrorComponent text={apiError} variant="error" />}
        <div className="p-4 d-flex justify-content-between align-items-center page-header">
          <span className="theme-font-inter-medium theme-font-size-16">
            Units
          </span>
          <div>
            <ButtonComponent
              text="Add Unit"
              onClick={addUnit}
              variant="blue-1"
              loading={false}
              disabled={false}
            />
          </div>
        </div>
        <TableComponent data={units} header={UserTableHeader} />
      </div>
      <div>Testing data</div>
    </>
  );
}

export default Units;
